<template>
  <div>
    <div class="b-table mb-5">
      <div class="table-wrapper has-sticky-header table-container overflow-y-auto" style="max-height: 550px">
        <table class="table is-bordered">
          <thead>
          <tr v-if="dateLabel">
            <th colspan="999" class="p-0" style="border: 0">
              <p class="has-text-weight-bold has-text-dark is-size-5 mb-2">{{ dateLabel }}</p>
            </th>
          </tr>
          <tr class="header-row">
            <th rowspan="3">{{ $t('farm.pond') }}</th>
            <th rowspan="3">DOC</th>
            <th :colspan="(totalAnco + 1) * totalAncoTimes">{{ $t('time') }}</th>
          </tr>
          <tr>
            <th :colspan="totalAnco + (hideAction ? 0 : 1)" v-for="n in totalAncoTimes" :key="n">{{ n }}</th>
            <!--      do later      -->
            <!--            <th :colspan="totalAnco + 1" v-for="n in totalAncoTimes" :key="n">{{ $timeFormat(ancoTimes[n - 1].created_at) }}</th>-->
          </tr>
          <tr>
            <th v-for="n in (totalAnco + (hideAction ? 0 : 1)) * totalAncoTimes" :key="n" class="whitespace-nowrap">
              <span v-if="(n - 1) % (totalAnco + 1) + (hideAction ? 0 : 1) < totalAnco + 1">
              {{ $t('feed_tray.feed_tray') }}
              {{ (n - 1) % (totalAnco + (hideAction ? 0 : 1)) + 1 }}
              </span>
            </th>
          </tr>
          </thead>

          <tbody v-for="(item, key) in ancoData" :key="key">
          <tr>
            <td>{{ item.pond_name }}</td>
            <td>{{ doc(item.pond_id, item.stock_id) }}</td>
            <template v-if="!hideAction">
              <td v-for="n in (totalAnco + 1) * totalAncoTimes" :key="n">
                <div v-if="(n - 1) % (totalAnco + 1) + 1 < totalAnco + 1">
                  <div
                      v-if="item.data[Math.floor((n - 1) / (totalAnco + 1))] && item.data[Math.floor((n - 1) / (totalAnco + 1))].data_anco[Math.floor((n - 1) % (totalAnco + 1))]">
                    <div v-if="item.data[Math.floor((n - 1) / (totalAnco + 1))].is_edit">
                      <ValidationProvider :name="$t('feed_tray.feed_tray')  + ' ' + n" rules="required"
                                          v-slot="{ errors }"
                                          v-if="item.data[Math.floor((n - 1) / (totalAnco + 1))].edit.data_anco[Math.floor((n - 1) % (totalAnco + 1))]">
                        <b-field class="m-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                          <b-select placeholder="Input Data"
                                    v-model="item.data[Math.floor((n - 1) / (totalAnco + 1))].edit.data_anco[Math.floor((n - 1) % (totalAnco + 1))].sisa"
                                    style="min-width: 140px">
                            <option value="Habis">{{ $t('feed_tray.Habis') }}</option>
                            <option value="Sisa Sedikit">{{ $t('feed_tray.Sisa Sedikit') }}</option>
                            <option value="Sisa Banyak">{{ $t('feed_tray.Sisa Banyak') }}</option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>
                    <div v-else
                         :class="[ancoColor(ancoLabel(item.data[Math.floor((n - 1) / (totalAnco + 1))].data_anco[Math.floor((n - 1) % (totalAnco + 1))]))]">
                      {{
                        ancoLabel(item.data[Math.floor((n - 1) / (totalAnco + 1))].data_anco[Math.floor((n - 1) % (totalAnco + 1))])
                      }}
                    </div>
                  </div>
                </div>
                <div class="is-flex" v-else>
                  <div v-if="item.data[Math.floor((n - 1) / (totalAnco + 1))]">
                    <div class="is-flex is-justify-content-center"
                         v-if="!item.data[Math.floor((n - 1) / (totalAnco + 1))].is_edit">
                      <b-button type="is-secondary is-light"
                                @click="editAnco(item.data[Math.floor((n - 1) / (totalAnco + 1))])"
                                icon-left="pencil-outline" class="mr-2"></b-button>
                      <b-button type="is-danger is-light"
                                @click="deleteAnco(item.data[Math.floor((n - 1) / (totalAnco + 1))].anco_id)"
                                icon-left="trash-can-outline"></b-button>
                    </div>
                    <div class="is-flex" v-else>
                      <b-button type="is-danger" expanded class="is-square"
                                @click="cancelUpdate(item.data[Math.floor((n - 1) / (totalAnco + 1))])">
                        <b-icon icon="close"/>
                      </b-button>
                      <b-button type="is-success" expanded class="is-square"
                                @click="handleUpdate(`edit${item.data[Math.floor((n - 1) / (totalAnco + 1))].anco_id}`, item.data[Math.floor((n - 1) / (totalAnco + 1))].edit)">
                        <b-icon icon="check"/>
                      </b-button>
                    </div>
                  </div>
                </div>
              </td>
            </template>
            <template v-else>
              <td v-for="n in (totalAnco) * totalAncoTimes" :key="n">
                <div>
                  <div>
                    <div :class="[ancoColor(ancoLabel(item.data[Math.floor((n - 1) / (totalAnco + 1))].data_anco[Math.floor((n - 1) % (totalAnco ))]))]">
                      {{
                        ancoLabel(item.data[Math.floor((n - 1) / (totalAnco + 1))].data_anco[Math.floor((n - 1) % (totalAnco ))])
                      }}
                    </div>
                  </div>
                </div>
              </td>
            </template>
          </tr>
          </tbody>

          <tbody v-if="!ancoData.length">
          <tr>
            <td colspan="6">
              <p class="has-text-centered">{{ $t('no') }} Data</p>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>

    <products-feed-tray v-if="!hideChart"/>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import ProductsFeedTray from "@/components/Farm/Overview/Products/ProductsFeedTray";

export default {
  name: "FeedTrayAnalysis",
  props: {
    hideChart: Boolean,
    hideAction: Boolean,
    dateLabel: String,
  },
  components: {ProductsFeedTray},
  computed: {
    ...mapState('farm', [
      'analysis'
    ]),

    ...mapGetters('farm', [
      'selectedFarm',
    ]),

    anco() {
      return this.analysis.anco
    },

    selectedPonds: {
      get() {
        return this.$store.state.farm.overview.ponds
      },
      set() {
      }
    },

    totalAnco() {
      return this.ancoData.reduce((a, b) => {
        let total = b.data.reduce((c, d) => c > d.data_anco.length ? c : d.data_anco.length, 0)
        return a > total ? a : total
      }, 0)
    },

    totalAncoTimes() {
      return this.ancoData.reduce((a, b) => a > b.data.length ? a : b.data.length, 0)
    },

    ancoTimes() {
      return this.ancoData.reduce((a, b) => a.length > b.data.length ? a : b.data, [])
    },

    pond_ids() {
      return this.selectedPonds ? this.selectedPonds.map(e => e.pond_id) : []
    },

    ancoData() {
      let data = this.anco ? this.anco.data : null

      if (this.pond_ids && data) {
        return data.filter(e => this.pond_ids.includes(e.pond_id))
      }
      return data || []
    },
  },
  watch: {
    'analysis.date'() {
      this.getData()
    }
  },
  created() {
    let data = this.anco.data
    if (!data || !data.length) {
      this.getData()
    }
  },
  methods: {
    ...mapActions('farm', [
      'getAncoAnalysis',
    ]),

    doc(pond_id, stock_id) {
      if (this.selectedFarm.ponds) {
        let find = this.selectedFarm.ponds.find(e => e.pond_id === pond_id)

        if (find && find.stock) {
          let stock = find.stock.find(e => e.stock_id === stock_id)

          if (stock) return this.$getDOC(stock.stock_date, this.analysis.date)
        }
      }

      return '-'
    },

    async getData() {
      this.$loading()
      await this.getAncoAnalysis()
      this.$loading(false)
      this.$emit('loaded')
    },

    ancoLabel(value) {
      return value && value.sisa ? this.$t('feed_tray.' + value.sisa) : '-'
    },

    ancoColor(value) {
      switch (value) {
        case 'Habis':
          return 'has-text-success'
        case 'Sisa Sedikit':
          return 'has-text-warning'
        case 'Sisa Banyak':
          return 'has-text-danger'
      }
    },


    editAnco(item) {
      item.edit = {
        ...this._.cloneDeep(item),
        pond: this.selectedFarm.ponds.find(e => e.pond_id === item.pond_id),
        date: new Date(item.date)
      }
      item.is_edit = true
    },
    deleteAnco(id) {
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteAnco', id)
        await this.getData()
        this.$loading(false)
      })
    },
    cancelUpdate(item) {
      item.is_edit = false
      item.edit = {}
    },
    async handleUpdate(form_id, item) {
      this.$loading()
      await this.$store.dispatch('farm/updateAnco', {
        anco_id: item.anco_id,
        created_at: item.created_at,
        stock_id: item.stock_id,
        data_anco: item.data_anco,
      })

      await this.getData()

      this.$loading(false)
    },
  },
}
</script>
