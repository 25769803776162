<template>
  <div>
    <div class="is-flex is-align-items-center">
      <sg-select class="mr-2"
                 :items="ponds"
                 label="name"
                 :placeholder="`${$t('select')} ${$t('farm.pond')}`"
                 v-model="selectedPond"
                 @change="getData"
      />
    </div>

    <div class="my-3" v-if="!loading && selectedPond && chart.series.length">
      <apexchart type="area" height="350" :options="chart.options" :series="chart.series"></apexchart>
    </div>
    <p v-else class="has-text-centered">{{ $t('no') }} Data</p>

    <b-loading :is-full-page="false" v-model="loading"></b-loading>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "ProductsFeedTray",
  components: {
    SgSelect,
    apexchart: VueApexCharts,
  },
  data: () => {
    return {
      data: [],

      selectedPond: null,
      selectedStock: null,
      totalAnco: 0,

      loading: false,

      chart: {
        series: [],
        options: {
          chart: {
            type: 'area',
            height: 350,
            zoom: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },

          labels: 'Date',
          xaxis: {
            type: 'datetime',
          },
          grid: {
            padding: {
              left: 60,
              right: 0
            }
          },
          yaxis: {
            min: 0,
            max: 2,
            tickAmount: 2,
            labels: {
              show: true,
              showAlways: true,
              showForNullSeries: true,
              offsetX: 50,
              formatter: (value) => {
                switch (value) {
                  case 0:
                    return 'Sisa Banyak'
                  case 1:
                    return 'Sisa Sedikit'
                  case 2:
                    return 'Habis'
                }
              },
            },
          },
        },
      },
    }
  },
  computed: {
    canEditAnco() {
      return this.$store.getters["farm/canEditAnco"]
    },
    ponds() {
      return this.$store.state.farm.overview.ponds
    },
    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    }
  },
  created() {
    if (this.ponds.length) {
      this.selectedPond = this.ponds[0]
      this.getData()
    }
  },
  methods: {
    async getData() {
      let pond = this.selectedPond
      if (pond) {
        this.loading = true

        this.data = []

        let cycle = pond.selected_cycle
        if (cycle) {
          this.data = []
          this.sortedData = []

          this.selectedStock = null
          let stock = await this.$store.dispatch('farm/getStockOverview', pond)
          this.selectedStock = stock.find(e => e.cycle_id === cycle.cycle_id)

          if (this.selectedStock) {
            let res = {}

            res = await this.$store.dispatch('farm/getAnco', {
              farm_id: this.farm_id,
              stock_id: this.selectedStock.stock_id
            })
            if (!res.error && res.data && res.data.length) {
              this.data = res.data.map(e => {
                e.pond_name = pond.name
                e.is_edit = false
                e.edit = {}
                return e
              }).sort((a, b) => b.created_at > a.created_at ? 1 : -1)
            }
          }
        }
        this.generateChart()

        this.loading = false
      }
    },

    getSisaChartValue(value) {
      switch (value) {
        case 'Sisa Banyak':
          return 0
        case 'Sisa Sedikit':
          return 1
        case 'Habis':
          return 2
      }
    },

    generateChart() {
      let dates = []

      this.chart.series = []

      if (this.data && this.data.length && this.selectedStock) {
        let items = this.data.reduce((a, b) => {
          dates.push(b.created_at)
          a = a.concat(b.data_anco.slice(0, this.selectedPond.anco).map(e => {
            e.created_at = b.created_at
            return e
          }))
          return a
        }, []).map(e => {
          e.anco_name = this.$t('feed_tray.feed_tray') + ' ' + e.anco_name
          return e
        })

        dates = dates.sort((a, b) => a.created_at > b.created_at ? 1 : -1)

        let anco = items.reduce((a, b) => {
          let find = a.find(e => e.name === b.anco_name)

          if (!find) {
            a.push({
              name: b.anco_name,
              data: this._.cloneDeep(dates)
            })
          }

          return a
        }, []).map(e => {
          for (let i = 0; i < e.data.length; i++) {
            let data = e.data[i]
            let find = items.find(f => f.created_at === data && f.anco_name === e.name)
            if (find)
              e.data[i] = {
                x: data,
                y: this.getSisaChartValue(find.sisa)
              }
            else
              e.data[i] = {
                x: data,
                y: null
              }
          }

          return e
        })

        this.chart.options.tooltip = {
          x: {
            formatter: (value) => {
              return this.$dateFormat(value, 'DD MMM YYYY HH:mm') + ' - DOC ' + (this.selectedStock ? this.$getDOC(this.selectedStock.stock_date, value) : '-')
            }
          }
        }

        this.chart.series = anco
      }
    },
  }
}
</script>
